import { useState, useEffect, useRef } from "react";
import { bridge } from "./bridge";

function App() {
  const iframeRef = useRef(null);
  const [src, setSrc] = useState(localStorage.getItem("nav_iframeUrl") || "");

  useEffect(() => {
    bridge();
  }, [src]);

  const handleUrlChange = (e) => {
    setSrc(e.target.value);
    localStorage.setItem("nav_iframeUrl", e.target.value);
  };

  const handleClear = () => {
    setSrc("");
    localStorage.removeItem("nav_iframeUrl");
  };

  const handleReload = () => {
    iframeRef.current.contentWindow.postMessage(
      { key: "reload", value: true },
      "*"
    );
  };

  return (
    <div className="App w-full flex flex-col bg-gray-100">
      <div className="p-4 pr-10 pb-0 flex gap-5">
        <input
          type="text"
          className="w-full h-10 px-2 border border-gray-400 rounded-md"
          placeholder="Enter iframe URL address"
          value={src}
          onChange={handleUrlChange}
        />
        <button onClick={handleClear}>clear</button>
        <button onClick={handleReload}>reload</button>
      </div>
      <div className="h-full overflow-auto">
        <div className="flex items-center justify-center m-6 h-[500px] text-[100px] text-gray-100 rounded-md bg-gray-200">
          web page content
        </div>
        <div className="w-full aspect-video px-4">
          <iframe
            ref={iframeRef}
            id="navigator-iframe"
            title="navigator-container"
            src={src}
            className="w-full h-full shadow-[0px_4px_15px_5px] shadow-gray-300"
          />
        </div>
        <div className="flex items-center justify-center m-6 h-[500px] text-[100px] text-gray-100 rounded-md bg-gray-200">
          web page content
        </div>
      </div>
    </div>
  );
}

export default App;
