/**
 * Use function body as <script> content
 */

export const bridge = () => {
  const navigatorEl = document.getElementById('navigator-iframe');

  const postMessage = (key, value) => {
    navigatorEl.contentWindow.postMessage({ key, value }, '*');
  };

  const receive = async (event) => {
    const { data: { key, value } } = event;

    // Sending the params when iframe listener initialized
    if (key === 'init') {
      postMessage('params_init', window.location.search);
    }

    // Copying and sharing URL
    if (key === 'share' && value === 'url') {
      // Copying URL
      navigator.clipboard.writeText(window.location.href)
      .catch((err) => {
        console.error('Copy error: ', err);
      });
      // Sharing URL
      try {
        if (navigator.canShare) {
          await navigator.share({
            title: "Navigator",
            url: window.location.href,
          });
        } else {
          console.error('Web Share API not supported.');
        }
      } catch (err) {
        console.error('Share error: ' + err);
      }
    }

    // Triggering actions only for fav and objectId keys
    if (['fav', 'objectId'].includes(key)) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      // URL params update
      if (value === 'clear') params.delete(key);
      else params.set(key, value);

      url.search = params.toString();
      window.history.replaceState({}, '', url);
    }
  };

  if (navigatorEl) {
    window.addEventListener('message', receive);
  }
}
